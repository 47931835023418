export const findRegionByCountryCode = (countryCode: string) => {
  const regions = {
    apac: ['AU'],
    us: ['US', 'CA'],
    emea: ['GB', 'IE'],
  };

  for (const region in regions) {
    if (regions.hasOwnProperty(region)) {
      if ((regions as any)[region].includes(countryCode)) {
        return region;
      }
    }
  }

  return 'us';
};
