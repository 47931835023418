export const APP_FEATURES: any = {
  badgeNew: {
    au: 'New',
    de: 'Neu',
    en: 'New',
    es: 'Nuevo',
    fr: 'Nouveau',
    nl: 'Nieuw',
    'pt-br': 'Novo',
    us: 'New',
  },
};
