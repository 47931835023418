import styles from './AppFeatures.module.scss';

import { APP_FEATURES } from '@/utilities/constants/AppFeatures';
import { findRegionByCountryCode } from '@/utilities/helpers/Region';
import { Fragment } from 'react';
import { useCountry } from '@/utilities/contexts/CountryContext';
import { useLocale } from '@/utilities/contexts/Locale';
import ArrowRight from '@/icons/maggie/ArrowRight';
import Badge from '@/components/atoms/Badge';
import Card from '@/components/molecules/Card';
import cn from 'classnames';
import Column from '@/components/atoms/Column';
import Container from '@/components/atoms/Container';
import DOMPurify from 'isomorphic-dompurify';
import dynamic from 'next/dynamic';
import Row from '@/components/atoms/Row';
import typeAppFeatures from '@/utilities/types/AppFeatures';

const Button = dynamic(() => import('@/components/atoms/Button'));
const Buttons = dynamic(() => import('@/components/molecules/Buttons'));
const Typography = dynamic(() => import('@/components/atoms/Typography'));
const Image = dynamic(() => import('next/image'));

interface AppFeaturesProps {
  content: typeAppFeatures;
  index: number;
}

const AppFeatures = (props: AppFeaturesProps) => {
  const { content, index } = props;

  const locale = useLocale();
  const country = useCountry();
  const region = findRegionByCountryCode(country);

  const cards = content.cards;
  const features = content.features;
  const featuresTitle = content.featuresTitle;
  const cardsRegional =
    (cards &&
      cards.filter(
        (card) => card.region?.includes('all') || card.region?.includes(region),
      )) ||
    cards;
  const featuresRegional =
    (features &&
      features.filter(
        (feature) =>
          feature.region?.includes('all') || feature.region?.includes(region),
      )) ||
    features;

  return (
    <section className={cn(styles.root)} id={`row-${index + 1}`}>
      <div className={styles.headerContainer}>
        <Container>
          <Row justify="center" key={'intro-media'}>
            <Column
              xs={12}
              md={8}
              lg={5}
              className={styles.introSection}
              key={'intro'}
            >
              {content.content &&
                content.content.map((block, index: number) => (
                  <Fragment key={index}>
                    {block?.title && (
                      <Typography
                        xs={6}
                        md={7}
                        lg={8}
                        tag="h2"
                        family="poppins"
                        className={styles.title}
                      >
                        {block?.title}
                      </Typography>
                    )}
                    {block?.editor && (
                      <Typography
                        xs={3}
                        md={4}
                        tag="div"
                        className={styles.intro}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(block.editor),
                          }}
                        />
                      </Typography>
                    )}
                    {block?.links && (
                      <Buttons
                        buttons={block?.links}
                        className={styles.links}
                      />
                    )}
                  </Fragment>
                ))}
            </Column>
            <Column key={'media'} xs={12}>
              <div className={styles.media}>
                {content?.leftImage && (
                  <div className={styles.leftImage}>
                    <Image
                      alt={content?.leftImage?.altText}
                      height={382}
                      width={198}
                      src={content?.leftImage?.mediaItemUrl}
                      unoptimized={true}
                    />
                  </div>
                )}
                {content?.middleImage && (
                  <div className={styles.middleImage}>
                    <Image
                      alt={content?.middleImage?.altText}
                      height={463}
                      width={850}
                      src={content?.middleImage?.mediaItemUrl}
                    />
                  </div>
                )}
                {content?.rightImage && (
                  <div className={styles.rightImage}>
                    <Image
                      alt={content?.rightImage?.altText}
                      height={346}
                      width={275}
                      src={content?.rightImage?.mediaItemUrl}
                    />
                  </div>
                )}
              </div>
            </Column>
          </Row>
        </Container>
      </div>
      <div className={styles.featuresContainer}>
        <Container>
          <div className={styles.content}>
            <Row key={'cards'} xs={2}>
              {cardsRegional &&
                cardsRegional.map((card, index: number) => (
                  <Column
                    xs={12}
                    md={6}
                    lg={4}
                    className={styles.card}
                    key={index}
                  >
                    <Card
                      card={card?.card}
                      customise={card.customise}
                      heading="h3"
                      withLearnMore
                    />
                  </Column>
                ))}
            </Row>
            <div className={styles.features}>
              {featuresRegional && (
                <>
                  {featuresTitle && (
                    <Typography
                      xs={6}
                      tag="h3"
                      family="poppins"
                      className={styles.featuresTitle}
                    >
                      {featuresTitle}
                    </Typography>
                  )}
                  <Row key={'features'} xs={2}>
                    {featuresRegional.map((feature, index: number) => (
                      <Column xs={12} md={6} lg={4} key={index}>
                        <Button
                          href={feature?.button?.url}
                          style="secondary"
                          size="large"
                          after={<ArrowRight width={16} height={16} />}
                          className={styles.feature}
                        >
                          {feature.icon && feature.icon?.mediaItemUrl && (
                            <i className={styles.featureIconleft}>
                              <Image
                                alt={feature.icon?.altText}
                                height={feature.icon?.mediaDetails.height || 30}
                                width={feature.icon?.mediaDetails.width || 30}
                                src={feature.icon?.mediaItemUrl}
                                priority={true}
                              />
                            </i>
                          )}
                          {feature?.button?.title && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  feature.button.title,
                                ),
                              }}
                            />
                          )}
                          {feature.new && (
                            <Badge
                              label={APP_FEATURES.badgeNew[locale]}
                              backgroundColor="purple-rain-tint-active"
                              style="classic"
                              className={styles.featureNew}
                            />
                          )}
                        </Button>
                      </Column>
                    ))}
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default AppFeatures;
